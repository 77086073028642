import React from "react"
import { Link } from "gatsby"

import logo from "../../content/assets/sss-icon.png"

const Layout = props => {
  const { title, page, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              <li
                className={"nav-home " + (page === "home" ? "nav-current" : "")}
                role="menuitem"
              >
                <Link to={`/`}>Home</Link>
              </li>
              <li
                className={
                  "nav-archives " + (page === "archives" ? "nav-current" : "")
                }
                role="menuitem"
              >
                <Link to={`/archives`}>Archives</Link>
              </li>
              {/* <li>
                <a href="http://news.samratchana.co.in?cat=2">News</a>
              </li> */}
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              <img
                src={logo}
                style={{ width: "32px", height: "38px", display: "inline" }}
              ></img>{" "}
              {title}
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <a href="mailto:mahajyothi.in@gmail.com">Contact Us</a>
              {/* <a
                href="https://www.facebook.com"
                title="Facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              <a
                href="https://twitter.com"
                title="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
              <Link
                to={`/rss.xml`}
                title="RSS"
                target="_blank"
                rel="noopener noreferrer"
              >
                RSS
              </Link> */}
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()}{" "}
        <a href="http://samratchana.co.in">Samratchana</a>
        {/* &mdash; Built with{" "}
        <a
          href="https://gatsbyjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Gatsby
        </a> */}
      </footer>
    </div>
  )
}

export default Layout
